body.account {
    .page-main {
        .sidebar-main {
            width: 25%;
        }
        .columns {
            .column.main {
                margin-top: 0;
            }
        }
    }
}

.customer-account-forgotpassword {
    .page-main {
        padding: 0 !important;
    }
}

.sales-order-view {
    .price-including-tax {
        display: flex;
    }
    .old-price {
        margin-right: 0.5rem;
    }
    .percent {
        margin-left: 0.5rem;
    }
}
