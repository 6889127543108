.page-layout-checkout {
    header.page-header {
        .header.content {
            padding: 15px 0;

            .logo {
                margin: 0 0 0 20px;
            }
        }
    }
    main.page-main {
        margin: 130px auto 0;
    }

    .footer-nav-button {
        margin: 0 0 2rem 0;
        float: right;
        padding-left: 0;
    }

    .page-bottom {
        width: $container-md;
        margin: auto;
    }

    // BUTTON SECTION

    #checkout-step-shipping_method button,
    .new-address-popup button,
    #checkout-payment-method-load button,
    .form.form-discount button,
    .modals-wrapper .modal-inner-wrap button,
    .authentication-wrapper button {
        display: block;
        max-width: 272px;
        font-size: 20px;
        padding: 12px 0;
    }

    .authentication-wrapper button {
        max-width: none;
    }
    // END BUTTON SECTION

    #checkout {
        position: relative;
    }

    .checkout-main-info {
        flex-direction: row;
        justify-content: space-between;

        div.msp-ui-inline-block {
            display: inline-block !important;
        }

        .adyen-checkout__input {
            border-top: 0.5px solid #000;
            border-left: 0.5px solid #000;
            border-right: 0.5px solid #000;
            border-bottom: none;
            border-radius: 0;
            color: #a7a7a8;
            letter-spacing: 0.5px;
            opacity: 1;
            width: 100%;
            font-size: 14px;
            padding: 0.75rem 1rem;
        }

        .adyen-checkout__label__text {
            color: #a7a7a8;
        }

        .adyen-checkout__checkbox__label {
            color: #a7a7a8;
        }

        .opc-wrapper {
            width: 66%;
            max-width: 500px;

            .step-title {
                font-size: 1.3rem;
                @include border-bottom;
            }
        }

        .checkout-main-summary {
            margin-top: -40px;
            width: 320px;
            max-width: 320px;
        }

        //------------------Checkout steps-----------------

        #checkoutSteps {
            .button.action.continue.primary {
                margin-left: auto;
            }

            #checkout-shipping-method-load {
                tbody {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto;
                }
                tr {
                    padding-left: 25px;
                }
            }
        }

        // ---------- Cart Summary section ------------

        .opc-block-summary {
            border: none;
            margin: 0;
            padding: 0;
        }

        // CHeckout minicart section

        .content.minicart-items {
            margin: 0;
        }

        .checkout-payment-method {
            .payment-method {
                .payment-method-title {
                    padding: 15px 0;
                }
                &._active {
                    border: solid 2px $cyan;
                    position: relative;
                    margin: 0 -14px;
                    &:after {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 29px;
                        height: 27px;
                        padding-top: 2px;
                        background: #03dcff;
                        color: $white;
                        text-align: center;
                        font-family: 'icons-blank-theme';
                        font-size: 27px;
                        line-height: 21px;
                        content: '\e610';
                    }

                    .payment-method-title {
                        padding-left: 12px;
                    }

                    .payment-method-content {
                        display: block;
                        padding-left: 34px;
                    }
                }
            }

            .action.action-show {
                cursor: pointer;
                color: $grey;
                &:after {
                    color: $pink;
                }
            }

            .payment-option-title .action-toggle {
                margin: 16px 0;
                color: $cyan;

                &:after {
                    color: $cyan;
                    transform: rotate(0);
                    @include transition(transform 0.3s ease);
                }
            }

            .payment-option._active {
                .payment-option-title .action-toggle:after {
                    color: $pink;
                    transform: rotate(180deg);
                }
            }
        }

    }

    .authentication-wrapper {
        .action-auth-toggle {
            width: 100%;
        }
        aside.authentication-dropdown {
            width: 50vh;
            left: unset;
            right: 0;
            .modal-inner-wrap {
                background-color: $very-dark-1;
                height: 100vh;
                padding: 90px 20px;

                .block-customer-login {
                    .block-title {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .checkout_copyright {
        margin: 0;
        padding: 12px 21px;
    }

    .shipping-address-item {
        width: 45% !important;
    }
}
