.breadcrumbs {
    .item {
        font-size: $normal-font-size;

        a {
            font-size: $normal-font-size;
        }

        &:not(:last-child):after {
            margin: -1px 4px 0 2px;
            font-size: 0.9rem;
        }
        &:last-child,
        strong {
            font-size: $normal-font-size;
        }
    }
}
