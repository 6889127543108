.catalog-product-view {
    font-size: 0.7rem;

    .page.messages {
        margin: 0px;
    }

    .column.main > *:not(:last-child) {
        margin-bottom: 5rem;
    }

    .product-main-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .page-title-wrapper {
        padding: 0;
    }

    .breadcrumbs {
        margin: 0 0 1.3rem;
    }

    //Main info
    .product-info-main {
        @include border-block;
        width: auto;
        max-width: 328px;
        margin-left: 20px;
        padding: 0;
        font-size: 0.8rem;

        .price-box .price-wording {
            font-size: 1rem;
        }

        & > *:not(.base-picture) {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        .product_tags {
            display: block;
        }

        .base-picture {
            img {
                max-height: 185px;
                object-fit: cover;
                background-position: center;
            }
        }

        .unavailable-stock-message,
        .stock.unavailable {
            font-size: 1rem;
        }
    }

    //Media
    .product.media {
        width: 70%;
        max-width: 848px;
        min-width: 70%;
    }

    //Details

    .gameo-tab {
        display: block;
        line-height: 1rem;

        h3.product-title {
            font-size: 1.05rem;
            color: $light;
        }

        .tab-section__lang,
        .tab-section__details {
            padding-right: 0;

            .grid-container {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;
            }
        }

        #product_details .tab-section__lang .grid-container {
            grid-template-rows: auto;
            grid-template-columns: repeat(6, 1fr);
        }

        #product_details,
        #description {
            .tab-section__lang img {
                height: 21px;
                overflow: visible
            }
        }

        .tab-content h2 {
            font-size: 1rem;
        }

        .tab-content:not(#description) {
            .langs-supported,
            .langs-subtitle,
            .section_details .details-wrapper,
            .tab-section__requirements {
                @include border-left;
            }
        }

        .tab-section__description .tags {
            margin: 1rem 0;
        }

        .langs-supported__wrapper,
        .langs-subtitle__wrapper {
            margin-bottom: 2rem;
        }
    }

    //Slider

    //---- Main
    .product-page-main-slider {
        width: 100%;
        margin: 0;
        .splide__track {
            width: 100%;
            max-width: 848px;
            aspect-ratio: 16/9;
        }

        .slide,
        .slide-image {
            height: 100%;
            figure.background-image {
                display: block;
            }
            figure:not(.background-image) img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
        }
        .slide-image {
            max-height: none;
        }
    }

    //----- Thumbs

    .product-page-main-slider-thumbs {
        display: grid;
        grid-template-columns: 76px 1fr 76px;
        align-items: center;
        justify-content: center;

        .video-preloader-wrapper {
            position: relative;
            height: 100%;

            & > img {
                height: 50px;
                width: 50px;
                @include absolute-centring;
            }
        }
        .splide__slide.slide-thumbs.screen-white-background {
            @include white-background-img;
        }

        .slider-products-preloader {
            grid-column-start: 2;
            grid-column-end: 3;
            height: calc(100% - 2rem);
        }

        .splide {
            &__track {
                display: flex;
                align-items: center;
                margin: 1rem 0;
                grid-column-start: 2;
                grid-column-end: 3;
                overflow: hidden;
                // I don't use border-block mixin here cause border makes splide not working ¯\_(ツ)_/¯
                @include background-color;
                box-shadow: 1px 1px 14px $black, -1px -1px 6px rgba(248, 247, 242, 0.15);
                -moz-box-shadow: 1px 1px 14px $black, -1px -1px 6px rgba(248, 247, 242, 0.15);
                -webkit-box-shadow: 1px 1px 14px $black, -1px -1px 6px rgba(248, 247, 242, 0.15);
                outline: 2px solid $dark-1;
            }

            &__arrows {
                display: flex;
                align-content: center;
            }
        }
        .splide__arrow--prev,
        .splide__arrow--next {
            margin: 1rem;
            position: relative;
            left: unset;
            right: unset;
            transform: unset;
        }
    }

    .splide__slide {
        height: auto;

        &.slide-thumbs {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
            &.is-active {
                opacity: 1;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    @include turquoise-cyan-border-gradient-lr;
                    border-width: 4px;
                    border-style: solid;
                }
            }
            & > img {
                aspect-ratio: 16/9;
                object-fit: cover;
                background-position: center;
            }
        }
    }

    // Additional Content
    .crosssell-wrapper {
        padding: unset;

        h2 {
            padding: 0px;
        }

        .crosssell-accordion-wrapper {
            display: none;
        }

        .crosssell-product-list-items {

            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: 24px;
            grid-row-gap: 24px;
            background-color: $dark-2;
            @include border-left;
            border-color: $grey-dark;
        }
    }

    //Complete package

    .block.upsell {
        h2 {
            font-size: 1rem;
        }

        .block-title.title {
            padding: 0;
        }

        ol.products.list.product-items {
            margin: 0;
        }

        .block-content.content {
            margin: unset;

            li.product-item {
                margin-bottom: 0;
            }
        }
        fieldset {
            padding: 24px 120px;
            @include turquoise-cyan-border-gradient-bt;
            border-left: 1px solid;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            row-gap: 2rem;
            column-gap: 4rem;

            .field.option {
                margin: 0;

                &:not(:first-of-type)::before {
                    content: '\f067';
                    @include font-awesome;
                    font-weight: 300;
                }

                &:before {
                    position: absolute;
                    left: -2rem;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                    color: $cyan;
                }

                &:last-of-type {
                    transform: none;
                    margin-bottom: 50px;

                    .price-cart-button {
                        flex-direction: row-reverse;
                        align-items: flex-end;
                        width: 272px;
                        transform: translate(-47%, 120%);
                    }

                    .product-item-small .product-item-inner {
                        display: block;

                        button {
                            width: 184px;
                            @include cyan-blue-gradient-127deg;
                        }
                    }

                    &::after {
                        display: none;
                    }
                }
            }

            .field.option.required {
                height: fit-content;
                width: fit-content;
            }
        }

        .product-item-info.add-to-card {
            display: none;
        }
    }
    //Related
    .block.related {
        h2 {
            font-size: 1.4rem;
        }
    }

    //Mobile component hide
    .product-page-main-slider .splide__pagination,
    .product.media .section_description,
    .product.media .product_tags,
    .product_details_mobile {
        display: none;
    }

    .cisa-alert-action {
        button {
            span {
                line-height: 1rem;
            }
        }
    }

    //tags
    .product-info-main {
        .tags_list {
            &.minimize {
                height: 24px;
                overflow: hidden;
            }
        }
        .product_tags {
            display: none;
            .tags-button {
                display: block;
            }
        }
    }
}

