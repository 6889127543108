.coming-soon {
    margin: 0;
    display: grid;
    align-content: space-between;
    &-info {
        &-title {
            margin-bottom: 4px;
            h3 {
                font-size: 16px;
                line-height: 20px;
                .platforms-icons {
                    height: 20px;
                    .platforms-icon {
                        i {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        &-description {
            p {
                letter-spacing: 0.28px;
            }
        }

        &-actions {
            .action.primary {
                font-size: 16px;
                line-height: 16px;
                padding: 12px 28px;
            }
        }
i
        &-countdown {
            margin: 10px 0;
        }
    }

    &-image {
        min-height: 172px;
    }

    .slider-footer {
        bottom: 0;

        .slider-pagination-coming-soon {
            font-size: 20px;
            line-height: 20px;
        }
    }
}
