.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        .modal-component {
            .amgdprcookie-main-wrapper {
                .amgdprcookie-settings-form {
                    .amgdprcookie-form-container {
                        height: calc(65vh - 2 * (3px + 15px) - (1.2rem + 3rem) - 54px - 18px);

                        .amgdprcookie-text {
                            letter-spacing: 0.28px;
                            color: $grey;
                            opacity: 1;
                            line-height: 24px;
                            font-size: 1rem;
                            @media (min-width: 991px) {
                                font-size: 0.8rem !important;
                            }
                        }
                    }
                }
                .amgdprcookie-policy-text {
                    span {
                        font-size: 0.8rem !important;
                    }
                    a span {
                        font-size: 0.9rem !important;
                    }
                }
            }
        }
    }
}
