.product-items {
    display: flex;
    flex-wrap: wrap;
}
.product-item {
    .product-item-info {
        .image-ribbon {
            margin-left: 3px;
            margin-top: 3px;
        }
        .product-item-details {
            padding-top: 0.5rem;
        }
        .product-item-name {
            height: 36px;
            line-height: 36px;
            a.product-item-link {
                font-size: 1rem;
                line-height: 1.2rem;
            }
        }
    }
}

.page-with-filter .product-items > li .product-item {
    @include product-item-image-zoom;
}
