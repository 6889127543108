.block.newsletter {
    height: 220px;
    padding-top: 100px;
    background: transparent
        linear-gradient(0deg, #ff009cb3 0%, #870e59b3 35%, #55143db3 50%, #1b1b1cb3 95%, #1b1b1cb3 100%) 0% 0%
        no-repeat padding-box;

    .newsletter-content {
        width: $container-md;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;

        .title {
            margin: 0;
            width: auto;

            .title-text {
                color: $pink;
                font-size: 24px;
                text-transform: capitalize;
            }
            .subtitle-text {
                display: block;
                font-size: 14px;

                & > span.pink {
                    color: $pink;
                    font-weight: 500;
                }

                .important {
                    font-size: 1.2rem;
                }
            }
        }

        .content {
            .field-wrapper {
                display: flex;

                input[type='email'] {
                    font-size: 14px;
                    width: 256px;
                    height:48px;
                }

                .newsletter-button {
                    margin: 0 0 0 10px;
                    min-width: 184px;
                    height: 48px;
                }
            }
        }

        .follow-us {
            align-items: center;
            margin-top: 0;

            p {
                font-size: 14px;
            }

            .fa-instagram,
            .fa-facebook-f {
                &:before {
                    font-size: 28px;
                }
            }
        }
    }
}
