// Relative to result index page
.catalogsearch-result-index {
    .toolbar-sorter {
        position: relative !important;
        margin-top: 0rem !important;
        margin-bottom: 2rem;
        @media (min-width: 768px) {
            margin-bottom: 0;
        }

        &:last-of-type {
            display: none;
        }
    }
}

.catalog-category-view,
.catalogsearch-result-index,
.ambrand-index-index {

    .amshopby-descr {
        .header-banner {
            .overlay {
                .title {
                    padding: 2rem;

                    & * {
                        &.text {
                            margin-left: 1.5rem;
                            line-height: 2rem;
                            letter-spacing: 1.25px;
                        }
                    }
                }
            }
        }

        & > *:not(.header-banner) {
            padding: 0;
            font-size: 1rem;
            line-height: 1.1rem;
        }
    }

    .columns {
        align-items: flex-start;
    }

    .breadcrumbs {
        padding-top: 0;
        padding-left: 0;
    }

    .columns .column.main {
        width: 80%;
        padding: 0 0 0 45px;

        .amscroll-bottom {
            font-size: 0.8rem;
        }

        .amscroll-load-button {
            margin-top: -1.5rem;
        }

        .filter-mobile {
            display: none;
        }

        .filter-down {
            margin-left: 6rem !important;
        }

        .product-items {
            li.product-item {
                max-width: 31% !important;
            }
        }

        .page-title-wrapper {
            margin-top: 6px;
        }

        #amasty-shopby-product-list {
            margin-top: 0;

            .toolbar-sorter.sorter {
                display: flex;
                align-items: center;
                margin: 1.5rem 0 3rem 0;
                text-align: left;

                .sorter-label {
                    margin-right: 4px;
                }
            }
        }

        #amasty-shopby-product-list .toolbar-sorter {
            display: block;
        }
    }

    // filters
    .sidebar.sidebar-main {
        position: static;
        z-index: 0;
        width: 20%;
        padding: 0;
        margin-top: 10px;

        .block.filter {
            // Filter actions
            .block-title.filter-title {

                .block-actions {
                    justify-content: space-between;
                }

                // Filter by dropdown
                .action.filter {
                    cursor: initial;
                    pointer-events: none;

                    i::before {
                        content: none;
                    }
                }

                // Show on desktop
                .action.clear {
                    display: flex;
                    align-items: center;
                    color: $pink;
                    font-size: $normal-font-size;
                    line-height: $normal-font-size;

                    span {
                        font-family: $font-medium;
                        font-size: $normal-font-size;
                    }

                    i:before {
                        margin-left: 5px;
                        background-image: linear-gradient(90deg, $pink 0%, $pink 48%, $purple-light 100);
                        color: transparent;
                        -webkit-background-clip: text;
                    }
                }

                // Sort by dropdown
                .action.sort {
                    display: none;
                }
            }

            // Filter options wrapper
            .block-content.filter-content {
                display: block;
                padding: 0;
                margin: 0;
                box-shadow: none;
                background: none;
                border: none;

                // Hide this action on desktop
                .action.clear {
                    display: none;
                }

                // Active filters badges
                .filter-result {
                    display: none;
                }

                // Shopping options text
                .block-subtitle.filter-subtitle {
                    display: none;
                }

                .filter-options {

                    .filter-options-title {
                        margin: 1rem 0 1.5rem 0;
                        cursor: pointer;
                        transition: color 0.3s ease;
                        pointer-events: initial;

                        &.wrap:after {
                            @include font-awesome;
                            display: inline-block;
                            padding: 0 0.6rem;
                            font-size: 0.7rem;
                            transition: transform 0.3s ease;
                            content: '\f078';
                        }

                        &.active {
                            color: $grey;

                            &:after {
                                transform: rotate(-180deg);
                            }
                        }
                    }

                    .filter-options-content {
                        display: none;

                        .-am-singleselect,
                        .-am-multiselect {
                            & [class*='am-filter-item'] {
                                padding-left: 0 !important;
                            }
                        }

                        // Filter options
                        .items:not(.am-filter-items-price) {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px 0;
                        }

                        .item {
                            flex: 1 50%;
                            padding-right: 4px;
                            white-space: nowrap;

                            &:last-child {
                                margin-bottom: 1rem;
                            }
                        }

                        // Filter option label
                        .item .label {
                            margin-bottom: 0.25rem;
                            font-size: $normal-font-size;
                        }

                        // Filter option checkbox
                        .item .label::before {
                            color: $grey-dark;
                            font-size: 1rem;
                        }

                        // Filter option slider input
                        .amshopby-slider-wrapper {
                            height: 60px;

                            .amshopby-slider-summary {
                                margin-bottom: 0.75rem;
                                letter-spacing: 0.7px;
                            }

                            .amshopby-slider-container {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .block.filter.open .block-title.filter-title .action.filter i::before {
            transform: rotate(-180deg);
        }

        .am-filter-items-attr_price {
            margin-bottom: 2rem;
        }
    }
}
