header.page-header {
    > * {
        padding-left: 0px;
        padding-right: 0px;
    }

    .top-line-banner {
        margin: 0px;

        .top-line-banner-item-wrapper {
            max-width: $container-md;

            .item {
                margin: 0;
            }
        }
    }

    .navigation-container {
        padding-bottom: 0;
        .link-search {
            display: none;
        }

        .navigation {
            margin-top: 20px;
            padding-bottom: 9px;
            display: flex;
            position: static;

            .mobile.hamburger {
                display: none;
            }
            ul[role='menubar'] {
                display: flex;
                list-style: none;
                align-items: center;
                padding-top: 0;
                opacity: 1;
                width: unset;
                position: static;
                height: unset;
                overflow: hidden;
                li.level0 {
                    margin-right: 2.5rem;
                    padding-bottom: 5px;
                    text-transform: uppercase;
                    background-clip: padding-box;
                    border-bottom: 1px solid transparent;
                    border-image-slice: 0;
                    border-image-source: none;
                    box-shadow: none;
                    transition: all 0.3s ease;
                    & span {
                        transition: all 0.3s ease;
                    }

                    &:hover {
                        &.menu-deals {
                            @include pink-purple-border-gradient-lr;
                            box-shadow: 0 -7px 4px -8px $pink inset, 0 -7px 4px -8px $purple inset;
                            & > a > span {
                                @include pink-shadow;
                            }
                        }
                        & > ul.level0 {
                            opacity: 1;
                            visibility: visible;
                            & > li.level1 > ul.level1 {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                        &:not(.menu-deals) {
                            @include turquoise-cyan-border-gradient-lr;
                            box-shadow: 0 -7px 4px -8px $turquoise inset,
                                0 -7px 4px -8px $cyan inset;
                            & > a {
                                &:before {
                                    @include fa-linear-gradient-turquoise-blue;
                                }
                                & > span {
                                    @include cyan-shadow;
                                }
                            }
                        }
                    }

                    ul.level0 {
                        @include background-color;
                        box-shadow: 0 24px 12px -24px $black;
                        border-bottom: 3px solid $dark-1;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        display: flex;
                        position: absolute;
                        opacity: 0;
                        visibility: hidden;
                        left: 0;
                        right: 0;
                        top: unset;
                        padding: 34px calc((100% - #{$container-md}) / 2) 0;
                        margin-top: 6px;
                        z-index: 100;
                        flex-wrap: wrap;
                        overflow: hidden;
                        max-height: unset;
                        transition: all 0.15s ease 0.2s;
                        &:hover {
                            & > a {
                                text-shadow: none;
                            }
                        }
                        &.submenu {
                            position: absolute;
                        }

                        li.level1 {
                            margin-right: 35px;
                            & > a {
                                display: flex;
                                &::before {
                                    @include font-awesome;
                                    font-weight: 900;
                                    content: '\f111';
                                    font-size: 5px;
                                    align-self: center;
                                    transition: all 0.3s ease;
                                    margin-right: 0.5rem;
                                    @include fa-linear-gradient-turquoise-blue;
                                }
                                &:hover {
                                    color: $grey;
                                    &:before {
                                        color: $grey;
                                        box-shadow: none;
                                        -webkit-text-fill-color: unset;
                                    }
                                }
                                & > span {
                                    text-transform: uppercase;
                                }
                            }

                            ul.level1 {
                                transition: all 0.15s ease 0.2s;
                                position: static;
                                opacity: 0;
                                visibility: hidden;
                                display: flex;
                                flex-direction: column;
                                margin-top: 18px;
                                top: unset;
                                left: unset;
                                width: unset;
                                padding: 0;
                                overflow: hidden;
                                max-height: unset;
                                &.submenu {
                                    position: static;
                                }

                                li.level2 {
                                    margin: 0 0 22px 1rem;
                                    a {
                                        font-size: 12px;
                                        text-transform: none;
                                        transition: color 0.3s ease;
                                        &::before {
                                            content: none;
                                        }
                                        &:hover {
                                            color: $grey;
                                        }
                                    }
                                }
                            }

                            &.menu-products {
                                max-width: 275px;
                                list-style: none;
                                & > a {
                                    display: none;
                                }
                                & > ul {
                                    margin-top: 0;
                                }
                                a::before {
                                    content: none;
                                }
                                .product-item-small {
                                    &:not(:last-child) {
                                        margin-bottom: 38px;
                                    }
                                    .image-ribbon {
                                        .ribbon-inner {
                                            padding: 3px 8px;
                                        }
                                        & span {
                                            font-size: 0.7rem;
                                        }
                                    }
                                    .product-image-container {
                                        width: min-content !important;
                                        .product-image-wrapper {
                                            max-width: 115px;
                                            max-height: 75px;
                                        }
                                    }
                                    .product-item-inner {
                                        display: none;
                                    }
                                    .product-item-details {
                                        flex: 1;
                                        padding: 4px;
                                        .product-item-name {
                                            margin: 0;
                                        }
                                        .product-item-link {
                                            font-size: 0.7rem;
                                            text-decoration: none;
                                            &:hover {
                                                text-decoration: none;
                                            }
                                        }
                                        .price-cart-button {
                                            span.price {
                                                font-size: 0.9rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .reassuring {
                display: none;
                flex: 1;
                justify-content: flex-end;
                padding-bottom: 5px;
                .item {
                    display: flex;
                    align-items: center;
                    i:before {
                        font-size: 0.8rem;
                        margin-right: 10px;
                        display: block;
                    }
                    span {
                        font-size: 0.7rem;
                    }
                }
                .dot {
                    width: 2px;
                    height: 2px;
                    margin: 0 10px;
                    align-self: center;
                    &:before {
                        display: block;
                    }
                }
            }
        }
    }
    .header.content {
        display: flex;
        flex: 1;
        padding: 9px 0 0;
        .action.nav-toggle {
            display: none;
        }
        .btn-shadow,
        .action.primary {
            height: unset;
            font-size: unset;
            & span {
                font-size: unset;
            }
            & i:before {
                font-size: unset;
            }
        }
        .logo {
            padding: 0;
            &.beta::after {
                top: 100%;
            }
            .logo-game {
                width: 120px;
            }
            .logo-o-portal {
                width: 40px;
            }
        }
        .link-search {
            display: flex;
            align-self: center;
            flex: 3;
            justify-content: center;
            margin: 0 0 0 10px;
            .block-search {
                .block-content {
                    .control {
                        position: relative;
                        input {
                            width: 290px;
                        }
                        button.action.search {
                            display: none;
                        }
                        .search-autocomplete {
                            position: absolute;
                            top: 47px;
                            ul {
                                margin-top: 10px;
                                display: flex;
                                flex: 1;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                li {
                                    margin: 0 5px 5px 5px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 0.7rem;
                                    filter: drop-shadow(0px 0px 0px transparent);
                                    &:hover {
                                        @include white-hover-shadow;
                                        cursor: pointer;
                                    }
                                    & span.amount {
                                        display: none;
                                    }
                                    &:nth-child(n + 6) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .country-selector__wrapper {
            display: block;
        }
    }
    .minicart-wrapper {
        position: static;
        float: none;
        align-self: center;
        padding: 0;
        .action-wishlist,
        .action-minicart {
            margin-right: 32px;
            padding-top: 1rem;
        }
        .action.showcart {
            &:before {
                display: none;
            }
        }
    }
    .action.customer {
        position: relative;
        padding: 1rem 0;
        &.opened {
            border-image-slice: 1;
            @include pink-purple-border-gradient-lr;
        }
        .popin {
            box-shadow: 0 0 12px $black;
            padding: 0 3px 3px 3px;
            border-top: 1px solid transparent;
            width: 320px;
            margin-top: 35px;
            left: 50%;
            transform: translateX(-50%);
            border-image-slice: 1;
            @include pink-purple-border-gradient-lr;
            & i.close:before,
            & .back {
                position: absolute;
                cursor: pointer;
                top: 19px;
            }
            & .back {
                font-size: 0.9rem;
                left: 24px;
                & i:before {
                    margin-right: 8px;
                }
                & span {
                    color: $pink;
                }
            }
            & i.close:before {
                font-size: 1rem;
                right: 24px;
            }
            & .popin-account,
            & .popin-customer {
                @include background-color;
                padding: 51px 24px 0;
                display: flex;
                flex: 1;
            }
            & .popin-signin {
                display: flex;
                flex: 1;
                flex-direction: column;
                & .btn.signin {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                & .btn.signin {
                    margin: 21px 0;
                }
                & .register,
                & .signout {
                    & > span {
                        text-align: left;
                        font-size: 0.8rem;
                    }
                    & .btn-shadow {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        height: 48px;
                        & span {
                            margin-bottom: 0;
                            flex: 1;
                            text-align: center;
                            font-size: 0.95rem;
                        }
                        & i {
                            position: absolute;
                            right: 16px;
                        }
                    }
                }
                & .register {
                    & .btn-shadow {
                        margin-top: 13px;
                        & i:before {
                            font-size: 1.35rem;
                        }
                    }
                }
                & .signout {
                    display: flex;
                    flex: 1;
                    margin-bottom: 20px;
                    & .btn-shadow {
                        align-self: flex-end;
                    }
                }
                & .links {
                    display: flex;
                    flex-direction: column;
                    margin-top: 30px;
                    & a {
                        display: flex;
                        align-items: center;
                        align-self: flex-start;
                        margin-bottom: 18px;
                        position: relative;
                        font-size: 1rem;
                        &:hover {
                            & .fa-shopping-bag,
                            & .fa-box-full,
                            & .fa-user {
                                @include cyan-hover-shadow;
                            }
                            & .fa-heart {
                                @include pink-hover-shadow;
                            }
                        }
                        & span {
                            flex: 1;
                            margin-left: 12px;
                        }
                        & .link-icon {
                            width: 20px;
                            display: flex;
                            justify-content: center;
                            & i:before {
                                align-self: center;
                                transition: filter 0.3s ease;
                            }
                        }
                    }
                }
                & .hello-user {
                    display: flex;
                    align-items: center;
                    height: 21px;
                    &:hover {
                        img {
                            transform: rotateZ(180deg);
                        }
                    }
                    img {
                        width: 20px;
                        height: 22px;
                        margin-right: 12px;
                        transition: transform 0.3s ease;
                    }
                    .name {
                        color: $cyan-turquoise;
                        margin-left: 4px;
                    }
                    .apostrophe {
                        white-space: pre;
                    }
                }
                & .language-switcher {
                    display: none;
                }
            }

            & .popin-login {
                flex: 1;
                & .block-customer-login {
                    margin: 0;

                    & fieldset {
                        margin: 0;
                    }

                    & .block-title {
                        margin: 18px 0 25px 0;
                        & strong {
                            font-size: 1.2rem;
                            font-weight: 300;
                        }
                    }

                    & .field.password .control {
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: 15px;
                        & input {
                            width: min-content;
                            flex: 1;
                        }
                        & .action.btn-round {
                            margin-left: 1rem;
                            & * {
                                transition: none;
                            }
                        }
                    }
                    & .actions-toolbar {
                        margin-bottom: 0;
                        & .secondary {
                            margin-bottom: 30px;
                            text-align: right;
                            & span {
                                color: $grey;
                                &:hover {
                                    color: $grey-dark;
                                }
                            }
                        }
                    }
                }
                & .signin {
                    text-align: center;
                    font-size: 0.8rem;
                    margin-top: 17px;
                    & a {
                        & i:before {
                            margin-left: 5px;
                            margin-right: 2px;
                        }
                    }
                }
            }

            & .reassurance {
                display: flex;
                flex-direction: column;
                place-content: flex-end;
                padding: 0 24px 16px;
                @include background-color;
                & span {
                    font-size: 0.8rem;
                    margin-bottom: 5px;
                }
                & a {
                    font-size: 1rem;
                    align-self: flex-start;
                }
            }
        }
    }
    .authorization-link {
        display: list-item;
        list-style: none;
        align-self: center;
        margin-left: 25px;
    }

    .header.content {
        & > .authorization-link {
            display: block;
        }
        & > .action.help {
            align-self: center;
            display: block;
        }
    }
}
