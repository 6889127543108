.slider-products {
    margin: 0;
    &-header {
        .slider-filters {
            display: inline-block;
            margin-left: 15px;
            margin-bottom: 15px;
            position: relative;
            top: auto;
            right: auto;
            padding: 0;
            text-align: left;
            .slider-filter {
                display: inline-block;
                margin: 0 5px 0 0;
                font-size: 0.8rem;
                line-height: 1.3rem;
                letter-spacing: 0.48px;
                color: $grey;
                opacity: 0.8;
                pointer-events: auto;
                &.slider-filter-header {
                    display: none;
                }
                i {
                    font-size: 0.7rem;
                    padding-right: 2px;
                }
                a {
                    @include transition(color 100ms linear, opacity 100ms linear);
                    font-size: 0.8rem;
                    line-height: 1.3rem;
                    letter-spacing: 0.48px;
                    color: $grey;
                    opacity: 0.8;
                    &:hover {
                        color: $turquoise;
                        opacity: 1;
                    }
                }
            }
            &:after {
                display: none;
            }
            &.active {
                background: transparent;
                border: 0;
                top: auto;
                right: auto;
            }
        }
        .see-more {
            right: 0;
            a {
                font-size: 0.9rem;
                line-height: 1rem;
                i,
                svg {
                    font-size: 0.9rem;
                    line-height: 1rem;
                }
            }
        }
    }

    .splide {
        &__arrow {
            &--prev {
                @include absolute-vertical-centring;
                left: -4rem;
                z-index: 1;
                display: block;
            }

            &--next {
                @include absolute-vertical-centring;
                right: -4rem;
                z-index: 1;
                display: block;
            }
        }
    }
}
