@mixin shadow-button-link {
    background-color: $very-dark-1;
    box-shadow: 1px 1px 8px $black, -1px -1px 8px $grey-dark;

    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    svg,
    i:before {
        font-size: 1.2rem;
        margin: 0.65rem 0;
        @include transition(all 100ms linear);
        filter: drop-shadow(0px 0px 0px transparent);
    }
    &.pink {
        svg,
        i:before {
            color: $pink;
        }
        &:hover {
            svg,
            i:before {
                color: $pink-light;
                filter: drop-shadow(0px 0px 8px $pink-light);
            }
        }
    }
    &.cyan {
        svg,
        i:before {
            color: $cyan;
        }
        &:hover {
            svg,
            i:before {
                color: $turquoise;
                filter: drop-shadow(0px 0px 8px $turquoise);
            }
        }
    }
    &.light {
        svg,
        i:before {
            color: $light;
        }
    }

    &.pink,
    &.light,
    &.cyan {
        &:active,
        &:active .pink, // It there is sub element inside
        &:active .light,
        &:active .cyan {
            box-shadow: none;
            text-shadow: none;
            border: $black;
            background-color: $very-dark-2;
            svg,
            i:before {
                background-image: none;
                background-color: $grey;
            }
        }
    }

    &:active,
    &.active {
        svg,
        i:before {
            color: $grey;
        }
    }
}

.btn-round {
    @include shadow-button-link;
    width: 2.45rem;
    height: 2.45rem;
    &.big {
        width: 2.8rem;
        height: 2.8rem;
        & > i:before {
            font-size: 1.3rem;
            margin: 0.75rem 0;
        }
    }
}
