html,
body {
    font-size: 15px;

    main.page-main {
        margin: 230px auto 0;
    }
}

// Container size
@include container-sizing($container-md);
