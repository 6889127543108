body.contentmanager-contenttype-homepage {
    .page.messages {
        margin: 0px 0px;
    }
    .homepage {
        padding-top: 0;
        .bestsellers {
            .bestsellers-row {
                &:nth-child(n + 6) {
                    display: block;
                }
                &:nth-child(n + 11) {
                    display: none;
                }
                &:last-child {
                    display: none;
                }
            }
        }
        .availablenow {
            .availablenow-row {
                &:nth-child(n + 6) {
                    display: block;
                }
                &:nth-child(n + 11) {
                    display: none;
                }
                &:last-child {
                    display: none;
                }
            }
        }
        .slideshows {
            &::before,
            &::after {
                content: none;
            }
            margin: 0;
            justify-content: space-between;
            align-items: stretch;

            > div {
                height: auto;
            }
        }
        .slideshow {
            @include border-block();
            margin: 0;
            width: 70%;
            min-height: auto;

            .splide {
                &__track {
                    overflow: hidden;
                    margin: 0;
                }
            }

            .slide {
                background: none;
                box-shadow: none;
                border: 0;
                aspect-ratio: 16/9;
                .slide-image.slide-image--with-description {
                    &:after {
                        content: '';
                        width: 100%;
                        height: 50%;
                        position: absolute;
                        bottom: 0;
                        top: auto;
                        left: 0;
                    }
                    &.light {
                        &:after {
                            background: linear-gradient(
                                180deg,
                                rgba(255, 255, 255, 0) 0%,
                                rgba(0, 0, 0, 0.8) 100%
                            );
                        }
                    }
                    &.dark {
                        &:after {
                            background: linear-gradient(
                                180deg,
                                rgba(0, 0, 0, 0) 0%,
                                rgba(255, 255, 255, 0.8) 100%
                            );
                        }
                    }
                }
                .slide-info {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 2rem;
                    width: calc(100% - 4rem);
                    &-surtitle {
                        font-size: 12px;
                        line-height: 12px;
                    }
                    &-title {
                        position: relative;
                        margin-bottom: 8px;
                        h3 {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                    &-description {
                        display: block;
                        width: 35%;
                        margin-bottom: 20px;
                        p {
                            letter-spacing: 0.36px;
                        }
                    }
                    &-actions {
                        justify-content: flex-start;

                        .action.towishlist {
                            margin-right: 1rem;
                            text-shadow: none;
                        }
                        .action.primary {
                            font-size: 16px;
                            line-height: 16px;
                            padding: 16px 42px;
                        }
                    }
                    &.light {
                        color: $light;
                        .slide-info-surtitle,
                        .slide-info-title,
                        .slide-info-description {
                            text-shadow: 0 0 3px $black, 0 0 6px $black;
                        }
                    }
                    &.dark {
                        color: $black;
                        .slide-info-surtitle,
                        .slide-info-title,
                        .slide-info-description {
                            text-shadow: 0 0 3px $white, 0 0 6px $white;
                        }
                    }
                }
                .slide-image {
                    a {
                        display: block;
                        height: 100%;
                    }
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .coming-soon {
            width: 28%;
        }

        .slideshow-thumbnail {
            display: block;
        }

        .block-home {
            padding: 0;
            margin-top: 21px;

            // height / bloc = hauteur du parent - ( sommes des margin + margin bottom de la dernière carte de bestsale) à diviser par 4 blocs
            height: calc((100% - (21px * 3 + 3rem + 1rem)) / 4);

            &:first-of-type {
                margin-top: 3rem;
            }

            img {
                object-fit: cover;
                height: 100%;
            }
        }
        .hotdeals,
        .recommanded {
            margin-top: 3rem;
            min-height: 380px;
            @media (min-width: 1024px) {
                min-height: 370px;
            }
            .splide__track {
                margin: 0 -12px 0 -12px;
            }
            .splide__list {
                padding: 12px !important; //Override calculated padding splide list to have shadow on elements
            }

            .slider-products-header {
                .title {
                    margin-left: 0;
                }
            }
            .see-more {
                .only-mobile {
                    display: none;
                }
                .only-desktop {
                    display: block;
                }
            }
        }
        .bestsellers {
            min-height: 1240px;
            .bestsellers-row {
                .product-item-small {
                    .product-item-info {
                        .product.details {
                            width: calc(
                                100% - 148px
                            ); // 100% de la taille du parent - la largeur de .product-item-photo
                        }
                        .product-item-inner-long {
                            display: none;
                        }
                    }
                }
            }
        }
        .availablenow {
            .availablenow-row {
                .product-item-small {
                    .product-item-info {
                        .product.details {
                            width: calc(
                                100% - 148px
                            ); // 100% de la taille du parent - la largeur de .product-item-photo
                        }
                        .product-item-inner-long {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
