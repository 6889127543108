// Country Selector

.country-selector {
    &__wrapper {
        &::before,
        &::after {
            content: '\f111';
        }
    }

    &__modal {
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 12px $black;
        position: absolute;
        padding: 0 3px 3px 3px;
        border-top: 1px solid transparent;
        transform: translateX(-50%);
        background-color: $dark-1;
    }

    &__current {
        display: inline-block;
    }

    &__tab {
        padding: 0.8rem;

        &:nth-child(2) {
            padding: 0.8rem;
        }

        &-head {
            .label {
                font-size: 0.75rem;
            }
            .current {
                font-size: 0.85rem;
            }
        }
    }

    &__item {
        a,
        button {
            font-size: 0.75rem;
        }
    }
}
