.available-product-item-small {
    .product-item-info {
        .product-item-inner-long {
            .product-item-actions {
                .actions-primary {
                    button {
                        font-size: 0.9rem;
                        width: 110px;
                    }
                }
            }
        }
    }
}
