// Block Cart
.checkout-cart-index {
    .page-main {
        .main {
            .cart-container {
                .cart-item-wrapper {
                    .action.primary.checkout {
                        display: none;
                    }
                }
            }
        }
    }
}

// Minicart

.block-minicart {
    @include pink-purple-border-gradient-lr;
    min-width: initial;
    max-width: 378px;
    left: auto;
    margin-left: 20px;
    margin-top: 35px;
    .block-content {
        #btn-minicart-close {
            margin: 10px;
            &:before {
                content: '';
            }
            i:before {
                font-size: 1rem;
                background: transparent
                    linear-gradient(90deg, #ff009c 0%, #ff009c 48%, #9700e2 100%) 0% 0% no-repeat
                    padding-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .subtotal {
            font-size: 0.9rem;
            label {
                font-size: 0.9rem;
            }
        }
        .items-total {
            @media (min-width: 768px) {
                font-size: 0.9rem;
            }
        }
        .minicart-items-wrapper {
            max-height: 360px !important;
            height: auto !important;
            .minicart-items {
                & > li {
                    width: 100%;
                }
                .product-item-small .second-line-product .product-item-pricing .btn-round {
                    padding: 1px;
                }
            }
            .product-item {
                .product {
                    .first-line-product {
                        .product-image-wrapper {
                            img {
                            }
                        }
                        .product-item-details {
                            .product-item-name {
                                a {
                                    font-size: 1rem;
                                }
                            }
                            .product-price {
                                .price-container {
                                    .price,
                                    .percent {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
                .second-line-product {
                    .product-item-pricing {
                        margin-top: 0.5rem;
                        margin-bottom: 0.25rem;
                        .update-cart-item {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .secondary {
            margin-left: 1rem;
        }
    }
}
div#minicart-content-wrapper {
    margin-left: auto;
    margin-right: auto;
}
