.sales-guest-form,
.sales-guest-view {
    .breadcrumbs {
        margin: 0;
    }

    .page-title-wrapper {
        margin: 1rem 0 0.5rem 0;
    }

    .columns {
        margin: 0;
    }
}
