.wishlist-index-index {
    .column.main {
        position: relative;
    }
    .product-items {
        .product-item {
            .product-item-name {
                margin-top: 0;
                line-height: 20px;
                height: 45px;
                overflow: hidden;
                a {
                    font-size: 1rem !important;
                }
            }
            @media (hover: hover) {
                .product-item-info:hover > .wishlist-item-submenu {
                    display: block;
                }
            }
            .product-item-info .product-item-photo {
                .image-ribbon {
                    margin-left: 6px;
                    margin-top: 2px;
                }
            }

            .wishlist-item-submenu {
                .wishlist-item-cart {
                    height: 2.5rem;
                    .box-tocart {
                        margin-right: 10px;
                        label {
                            font-size: 0.8rem !important;
                        }
                        input {
                            width: 2.5rem !important;
                            margin-left: 0.25rem !important;
                        }
                    }
                    .button-edit {
                        margin-right: 10px;
                        padding: 0.75rem;
                        a {
                            font-size: 0.8rem !important;
                        }
                    }
                }
            }
            .wishlist-item-comment {
                .product-item-comment {
                    font-size: 0.8rem !important;
                }
            }
        }
    }
}

.customer-popup-ajaxwishlist {
    .fieldset {
        .label {
            span {
                font-size: 0.9rem;
            }
        }
    }
    .wishlist-pass a {
        font-size: 0.9rem;
    }
    .action.remind {
        font-size: 0.9rem;
    }
    .primary {
        span {
            font-size: 0.9rem;
        }
    }
}

.modal-popup_ajaxwishlist_magepow {
    .product-name {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
    .mgp-message-successs {
        font-size: 0.9rem;
    }
    .ajaxwishlist-buttons button {
        font-size: 0.9rem !important;
    }
}
