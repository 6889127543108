.cms-page-view {
    .breadcrumbs {
        margin: 0;
    }
    .page-title-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 1rem;

        .page-title {
            color: $white;
            font-size: 1.5rem;
            font-weight: 600;
            margin: 1rem 0;
        }
    }
    .page-main .column.main {
        padding: 0;

        p {
            font-size: 0.9rem;
        }
        ul,
        li {
            font-size: 0.8rem;
        }
    }
}

.cms-no-route {
    .page-main {
        padding: 0;
    }
}
