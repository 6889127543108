.page-footer {
    .footer-part {
        padding-top: 32px;
        .footer-content {
            width: $container-md;
            margin: auto;
            .footer-first-part {
                display: flex;
                align-items: flex-start;
                padding-top: 60px;

                .footer-nav-button {
                    position: relative;
                    margin-top: -97px;
                }

                .footer-options-links {
                    .footer-option {
                        padding-top: 0;
                        line-height: 18px;
                        padding: 9px 0;

                        &.hide-mobile {
                            display: block;
                        }
                    }
                }

                .main-menu-footer {
                    padding-top: 0;
                    .level-top {
                        display: flex;
                        a {
                            width: 100%;
                        }
                    }
                    .level0 {
                        padding: 0;
                    }
                    a.level0 {
                        padding: 9px 0;
                        line-height: 18px;
                    }
                }

                .second-menu-footer {
                    margin-top: 0;
                    padding: 0 0 0 16px;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    .level0 {
                        line-height: 14px;
                        padding: 9px 0 13px;
                    }
                }

                .reassurance .second-menu-footer
                {
                    li:last-child a {
                        margin-top: 0;
                    }
                }
            }

            .footer-separation {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .footer-second-part {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 0;

                .trustpilot-logo {
                    margin-top: 0;
                }

                .footer-reassurance {
                    margin-top: 0;
                }
                .trustpilot-logo .trustpilot-widget {
                    height: 24px;
                    a {
                        font-size: 0.8rem;
                    }
                }
                .reassurance-licence {
                    p {
                        font-size: 0.66667rem;
                        line-height: 0.66667rem;
                    }
                }

                .footer-payments-type {
                    margin-top: 0;
                }

                .footer-copyright {
                    text-align: right;
                    font-size: 12px;
                    box-shadow: none;
                    border: none;

                    .logo {
                        padding: 0;
                        width: 160px;
                        max-width: 160px;
                        margin: 0 0 6px;
                        align-self: flex-end;
                        justify-content: flex-end;
                        .logo-game {
                            max-width: 76px;
                        }
                        .logo-o-portal {
                            width: 25.6px;
                        }
                    }
                }
            }
        }
    }
}
