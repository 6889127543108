.exclusive-deals {
    display: block;
    margin: 48px 0 46px;

    .exclusive-deals-container {
        .exclusive-deal {
            box-shadow: none;
        }
        .ed-teaser-countdown,
        .ed-revealed-countdown,
        .ed-quantity-countdown {
            font-size: 0.75rem;
            line-height: 0.95rem;

            .block-days,
            .block-hours,
            .block-minutes,
            .block-current,
            .block-seconds {
                &.highlighting {
                    span:first-child {
                        font-size: 0.95rem;
                        line-height: 0.85rem;
                    }

                    span {
                        font-size: 0.75rem;
                        line-height: 0.85rem;
                    }
                }
            }

            .block-available {
                &.highlighting {
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                }
            }
        }

        .teaser-item,
        .revealed-item {
            flex-direction: row;
            justify-content: unset;
            height: 385px;
            width: 100%;

            .anchor {
                top: -75%;
            }

            .information {
                width: 58%;
                justify-content: center;
                //border: 1px solid transparent;

                > :not(.background) {
                    z-index: 1;
                }

                .background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.6;
                }

                .subtitle {
                    font-size: 1.1rem;
                    line-height: 0.95rem;
                    letter-spacing: 0.36px;
                }

                .title {
                    font-size: 1.5rem;
                    letter-spacing: 0.48px;
                    line-height: 0.95rem;
                    margin: 8px 0 11px;
                }
            }

            .subscribe {
                margin: 21px 0 19px;
                form {
                    .checkbox-area {
                        input[type='checkbox'] {
                            & + label {
                                font-size: 0.8rem;
                                letter-spacing: normal;
                                line-height: normal;
                                max-width: 100%;
                                text-align: left;
                                margin: 10px 0 0;
                            }
                        }
                    }
                }
                .success,
                .error {
                    .content {
                        margin-bottom: 0;
                    }
                }
            }

            .reveal,
            .promo {
                margin-top: 0;
                width: 48.5%;
                height: 86.5%;
                position: absolute;
                right: 0;
                @include border-block;
            }
        }

        .teaser-item {
            min-height: unset;
            background: unset;
            .information {
                margin-top: 0;
                //@include turquoise-cyan-border-gradient-lr;

                .background {
                    @include turquoise-dark-gradient;
                }

                .subtitle {
                    font-family: $font-light;
                    color: $cyan-turquoise;
                    margin-bottom: 4px;
                }

                .title {
                    height: 45px;
                    color: $light;
                    @include cyan-hover-shadow;
                }

                .ed-teaser-countdown {
                    margin-top: 0;
                    margin-bottom: 1rem;
                }

                .subscribe {
                    .action.secondary {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .revealed-item {
            min-height: unset;
            background: unset;
            .information {
                margin: 0;
                //@include pink-purple-border-gradient-lr;

                .title {
                    font-size: 1.2rem;
                    line-height: 0.95rem;
                    letter-spacing: 0.36px;
                    color: $pink;
                    @include pink-hover-shadow;
                }

                .subtitle {
                    font-size: 1.45rem;
                    letter-spacing: 0.48px;
                    line-height: 0.95rem;
                    margin-top: 19px;
                    color: $light;
                    @include pink-hover-shadow;
                }

                .background {
                    @include pink-dark-gradient;
                }

                .product-info {
                    flex-direction: row;
                    justify-content: unset;
                    align-items: unset;
                    margin-top: 29px;
                    width: unset;

                    .price-box {
                        .prices {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                            .old-price,
                            .new-price {
                                line-height: 16px;
                                padding: 0;
                            }

                            .old-price {
                                font-size: 0.85rem;
                                letter-spacing: 0.28px;
                            }

                            .new-price {
                                font-size: 1.2rem;
                                letter-spacing: 0.4px;
                            }
                        }

                        .promo-percent {
                            margin: 0 19px;
                            padding: 8px 11px;

                            .percent {
                                font-size: 1.2rem;
                                letter-spacing: 0.4px;
                                line-height: 1.1rem;
                            }
                        }
                    }
                    .cart {
                        margin-top: 0;
                        display: block;
                        position: unset;
                        .wishlist {
                            display: none;
                        }
                    }
                }
            }
            .promo {
                display: unset;
                > a {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                }
                .info {
                    padding: 0 0 45px 45px;
                    img {
                        max-height: 50px;
                    }
                    .details {
                        margin-top: 14px;
                        .wishlist {
                            display: block;
                        }
                        .product-name {
                            max-width: 85%;
                            margin-left: 26px;
                            font-size: 1.45rem;

                            & > a {
                                font-size: inherit;
                                line-height: 1.45rem;
                                letter-spacing: 0.48px;
                            }
                        }
                    }
                }
            }
        }

        .subscribe {
            .action:not(.important) {
                display: block;
            }
            .action.important {
                display: none;
            }
        }
    }
}
